<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-2xl text-primary font-bold">
                      Edit Web Content
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div :class="{ card: !isSubPage }" class="">
              <div>
                <template v-if="!loading">
                  <div class="grid">
                    <div class="col-12">
                      <form
                        ref="observer"
                        tag="form"
                        @submit.prevent="startRecordUpdate()"
                      >
                        <!--[form-content-start]-->
                        <div class="grid">
                          <div class="col-12">
                            <div class="formgrid grid">
                              <div class="col-12 md:col-3">Title *</div>
                              <div class="col-12 md:col-9">
                                <Textarea
                                  :class="getErrorClass('title')"
                                  class="w-full"
                                  ref="ctrltitle"
                                  rows="5"
                                  v-model="formData.title"
                                  placeholder="Title"
                                  type="textarea"
                                >
                                </Textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="formgrid grid">
                              <div class="col-12 md:col-3">Date to Show *</div>
                              <div class="col-12 md:col-9">
                                <Calendar
                                  :showButtonBar="true"
                                  class="w-full"
                                  :class="getErrorClass('datetoshow')"
                                  dateFormat="yy-mm-dd"
                                  v-model="formData.datetoshow"
                                  :showIcon="true"
                                  mask="YYYY-MM-DD"
                                  outlined
                                  dense
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--[form-content-end]-->
                        <div v-if="showSubmitButton" class="text-center my-3">
                          <Button
                            type="submit"
                            label="Update"
                            icon="pi pi-send"
                            :loading="saving"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </template>
                <template v-if="loading">
                  <div class="p-3 text-center">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { EditPageMixin } from "../../mixins/editpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "editWebcontentPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, EditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "webcontent",
    },
    idName: {
      type: String,
      default: "postid",
    },
    routeName: {
      type: String,
      default: "webcontentedit",
    },
    pagePath: {
      type: String,
      default: "webcontent/edit",
    },
    apiPath: {
      type: String,
      default: "webcontent/edit",
    },
  },
  data() {
    return {
      formData: {
        title: "",
        datetoshow: "",
      },
      submitted: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Edit Web Content";
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["webcontent/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("webcontent/setCurrentRecord", value);
      },
    },
  },
  validations() {
    let formData = {
      title: { required, maxLength: maxLength(75) },
      datetoshow: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("webcontent", ["updateRecord", "fetchRecord"]),
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Please complete the form", "Form is invalid", "error");
        return;
      }
      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let payload = this.normalizedFormData();
      let data = { id, url, payload };
      this.updateRecord(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(this.msgAfterUpdate);
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          if (this.redirect) this.navigateTo(`/webcontent`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
      this.formData.tag = this.$utils.toArray(this.formData.tag);
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        title: "",
        datetoshow: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
